import { default as em }
  from 'mithril'
import classNames
  from 'classnames'

export default class ComponentCubeSelectDimensionElements {
  constructor () {
    this.isCollapsed = true
  }

  view (vnode) {
    const model = vnode.attrs.model
    return em('.cube-select-dimension-elements', [
      this.renderHead(vnode, model),
      this.renderBody(vnode, model)
    ])
  }

  toggleCollapsed () {
    this.isCollapsed = ! this.isCollapsed
  }

  renderHead (vnode, model) {
    return em('.head', [
      em('.control', [
        em('input', { 
          type : 'checkbox', 
          checked : model.isSelectedAll, 
          indeterminate : !model.isSelectedAll && model.isSelectedSome,
          onclick : event => model.toggleSelectAll()
        })
      ]),
      em('.caption', {
        onclick : event => this.toggleCollapsed()
      }, [
        em('span', model.title),
        em('span', this.isCollapsed ? '+' : '-')
      ])
    ])
  }

  renderBody (vnode, model) {
    if (this.isCollapsed)
      return

    return em('ul.body', [
      model.items.map(each => {
        return em('li.item', {
          onclick : ev => model.toggleSelected(each)
        }, [
          em('input', { type : 'checkbox', checked : each.isSelected }), 
          em('span', each.label)
        ])
      })
    ])
  }
}

// vim:syntax=javascript
