import { default as em }
  from 'mithril'
import classNames
  from 'classnames'

export default class ComponentCubeSelectFacts {
  view (vnode) {
    const model = vnode.attrs.model
    return em('ul.cube-select-facts', [
      model.entries.map(entry => this.renderEntry(vnode, model, entry))
    ])
  }

  renderEntry (vnode, model, entry) {
    console.log(entry)

    return em('li', {
      onclick: ev => model.toggleOne(entry)
    }, [
      em('input', { type : 'checkbox', checked : entry.isSelected }),
      em('span', entry.title)
    ])
  }

}

// vim:syntax=javascript

