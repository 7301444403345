/* 2024 Jan Provaznik (jan@provaznik.pro)
 */

import { default as em }
  from 'mithril'
import Promise
  from 'bluebird'
import classNames
  from 'classnames'
import lo
  from 'lodash'

import product
  from 'cartesian-product'

//

import ModelCubeDimension
  from 'wtf/cube/modeldimension.es6'
import ModelCubeFacts
  from 'wtf/cube/modelfacts.es6'

import ComponentCubeSelectFacts
  from 'wtf/cube/selectfacts.es6'
import ComponentCubeSelectDimensionElements
  from 'wtf/cube/selectdimensionelements.es6'

//

import ChartBar
  from 'wtf/plot/chartbar.mjs'

//

async function bootstrap () {
  em.mount(document.body, ComponentPrototype)
}
window.addEventListener('load', 
  bootstrap)

// Remote request helpers
//

async function requestPull (path) {
  return em
    .request({
      url : path,
      background : true
    })
}

async function requestPush (path, body) {
  return em
    .request({
      url : path,
      body : body,
      method : 'POST',
      background : true
    })
}

// ModelDimension helper

async function makeModelDimension (remote, title, field) {
  return requestPull(remote)
    .then(payload => new ModelCubeDimension(payload, title, field))
}

//

class ComponentPrototype {
  constructor () {
    this.modelCubeFacts = new ModelCubeFacts([
      { 'field' : 'vl_ks', 'title' : 'Kusy' },
      { 'field' : 'vl_kc', 'title' : 'Koruny' }
    ])
    this.arrayModelCubeDimension = []

    Promise
      .all([
        makeModelDimension('/static/dim-year.json', 'Rok', 'i_rok'),
        makeModelDimension('/static/dim-month.json', 'Měsíc', 'i_mesic'),
        makeModelDimension('/static/dim-category.json', 'Kategorie', 'c_cat')
      ])
      .then(result => this.arrayModelCubeDimension = result)
      .finally(em.redraw)

    this.selectedAxis = null
  }

  renderControlsCube () {
    return [

      em('.section', [
        em('h2', 'Fakta (hodnoty)'),
        em(ComponentCubeSelectFacts, { 
          model : this.modelCubeFacts 
        })
      ]),

      em('.section', [
        em('h2', 'Dimenze'),
        this.arrayModelCubeDimension.map(modelCubeDimension => {
          return em(ComponentCubeSelectDimensionElements, { 
            model : modelCubeDimension 
          })
        })
      ])

    ]
  }

  renderControlsPlot () {
    const available = this.arrayModelCubeDimension
      .filter(model => model.isSelectedSome)

    return [
      em('.section', [
        em('h2', 'Výběr osy X'),
        em('ul.select-axis', [
          available.map(model => {
            return em('li', {
              onclick: event => this.selectedAxis = model.field
            }, [
              em('input', { type : 'checkbox', checked : model.field == this.selectedAxis }),
              em('span', model.title)
            ])
          })
        ])
      ])
    ]
  }

  renderPlot (vnode) {
    if (! this.arrayModelCubeDimension.length)
      return

    if (! this.modelCubeFacts.selected.length)
      return

    const xfield = this.selectedAxis
    if (! xfield)
      return

    const fentries = this.modelCubeFacts.selected
    const dentries = this.arrayModelCubeDimension
      .filter(model => model.field != xfield)
      .filter(model => model.isSelectedSome)
    const dvalues = dentries
      .map(model => model.selected.map(entry => entry.label))

    const xmodel = this.arrayModelCubeDimension
      .find(model => model.field == xfield)
    const xlabel = xmodel.title

    const xentries = xmodel.selected
    const xvalues = xentries.map(entry => entry.label)

    if (! xentries)
      return

    const outcomeYfields = []

    if (! dentries.length) {
      for (const fentry of fentries) {
        outcomeYfields.push({
          key : fentry.field,
          legend : fentry.title,
          color : randomColor()
        })
      }
    }
    else {
      const drecords = product(dvalues)
      for (const drecord of drecords) {
        const dfield = drecord
          .map((value, index) => `${dentries[index].field}_${value}`)
          .join('_')
        const dtitle = drecord
          .map((value, index) => `${dentries[index].title}:${value}`)
          .join(', ')

        for (const fentry of fentries) {
          outcomeYfields.push({
            key : dfield + fentry.field,
            legend : dtitle + ', ' + fentry.title,
            color : randomColor()
          })
        }
      }
    }
      
    //
    const outcomePayload = new Array

    for (const xvalue of xvalues) {
      const payloadRecord = {}
      payloadRecord[xfield] = xvalue
      for (const yfield of outcomeYfields) {
        payloadRecord[yfield.key] = Math.random() * 100
      }
      outcomePayload.push(payloadRecord)
    }

    console.log('yf', outcomeYfields)
    console.log('op', outcomePayload)
    
    const options = {
      lengths: {
        figure : { 
          height: 400,
        }
      },
      margins : {
        right: 300
      },
      display : {
        horizontal : {
          key : xfield
        },
        vertical : outcomeYfields
      },
      axes : {
        horizontal : {
          label : xlabel,
          ticks : {
            rotate: true,
            values: null,
          }
        },
        vertical : { 
          label : '...',
          ticks : {
            values: null,
            extend: true,
            format: ',.0f'
          }
        }
      }
    }

    return em('.column.plot', [
      em(ChartBar, {
        options,
        payload : outcomePayload
      })
    ])
  }

  view (vnode) {
    return em('.prototype', [
      em('.column.control-cube', [
        this.renderControlsCube(vnode),
        this.renderControlsPlot(vnode),
      ]),
      this.renderPlot(vnode)

    ])
  }

}

function fakePayload (xfield, xvalues, yfields) {
  return xvalues
    .map(xvalue => {
      const result = {}
      result[xfield] = xvalue
      yfields.forEach(yfield => {
        result[yfield] = Math.random() * 100
      })
      return result
    })
}

function randomColor () {
  return '#' + Math.floor(Math.random()*16777215).toString(16);
}


// vim:syntax=javascript
