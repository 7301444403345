export default class ModelCubeFacts {
  constructor (entries) {
    this.$entries = entries
  }

  get entries () {
    return this.$entries
  }

  get selected () {
    return this.entries.filter(entry => entry.isSelected)
  }

  get selectedSome () {
    return this.entries.some(entry => entry.isSelected)
  }
  get selectedEach () {
    return this.entries.every(entry => entry.isSelected)
  }

  toggleOne (entry) {
    entry.isSelected = !(entry.isSelected)
  }
}

// vim:syntax=javascript
