import merge from 'deepmerge';
import lo from 'lodash';
import em from 'mithril';
import d3 from 'wtf/d3.es6';

export function formatText (map, template) {
  return (template || '')
    .replace(/:\{(\w+)\|([^\|]+)\|?([^\}]*)\}/g, (match, key, fun, arg) => {
      if (key in map)
        if (fun in funs)
          return lo.get(funs, fun)(lo.get(map, key), arg);
        return match;
    })
    .replace(/\:(\w+)/g, (match, key) => {
      if (key in map)
        return lo.get(map, key);
      return match;
    });
};

// import * as dc from 'lib/d3-scale-chromatic';

export default /* ChartBarV */ {
  render : (vnode) => {
    let element = vnode.dom;
    let payload = vnode.attrs.payload;

    let object = createPlot(vnode.attrs.options).payload(payload);
    let update = () => {

      /* Flush the target element contents. */
      d3.select(element)
        .selectAll('*')
        .remove();

      /* Render the plot into the target element. */
      object.render(element, {
        width: element.clientWidth || 720
      });

      console.log('render chart/bar/vertical', object.serial);
    };

    window.addEventListener('resize', event => {
      requestAnimationFrame(update);
    });

    /* Update the plot for the first time. */
    update();
        
  },

  /* Mithril lifecycle methods. */
  oncreate : (vnode) => {
    vnode.state.render(vnode);
  },
  onupdate : (vnode) => {
    vnode.state.render(vnode);
  },
  view : (vnode) => {
    return em('.chart-bar.chart-bar-v');
  }
};

/* Intantiates the Plot. */

function createPlot (options) {

  var that = merge({
    payload: [],
    display: {
      horizontal : {},
      vertical : [
      ]
    },
    margins: { top: 0, left: 100, right: 100, bottom: 50 },
    lengths: {
      figure: { width: 720, height: 640 },
      legend: {
        line: { height: 20, skip: 5 },
        skip: 10
      },
      axis: {
        horizontal: { skip: 10 },
        vertical: { skip: 10 }
      }
    },
    axes: {
      horizontal: {
      },
      vertical: {
      }
    },
    classes : {
    },
    legends : {
      show: true
    }
  }, options);

  return Object.create({
    payload: function (payload) {
      if (arguments.length < 1) {
        return that.payload;
      }
      that.payload = Object.assign([], payload);
      return this;
    },
    render: function (target, size) {
      that.lengths.figure = merge(that.lengths.figure, size || {});

      renderT(target, that, that.payload)
      return this;
    },
    serial : '1243'
  });
}

function renderT (target, options, payload) {

  /* Compute the lengths of the components. */
  var bodyX = options.margins.left;
  var bodyY = options.margins.top;
  var bodyW = options.lengths.figure.width - options.margins.left - options.margins.right;
  var bodyH = options.lengths.figure.height - options.margins.top - options.margins.bottom;

  /* Compute the domains. */
  var domainX = payload.map((row) => row[options.display.horizontal.key]);
  var domainY = d3.merge(options.display.vertical.map((field) => {
    return payload.map((row) => row[field.key]);
  }));

  if (options.refline && Array.isArray(options.refline)) {
    domainY = d3.merge([ options.refline, domainY ]);
  }

  var domainG = d3.range(0, options.display.vertical.length);

  /* Extend the domainY to cover (0, ...) */
  if (options.axes.vertical.ticks.extend) {
    domainY.push(0);
  }

  /* Prepare the horizontal, vertical, and grouping scales. */
  var scaleY = d3.scaleLinear()
    .domain(d3.extent(d3.merge([ domainY, [ 0 ] ])))
    .rangeRound([ bodyH, 0 ])
    .nice();

  var scaleX = d3.scaleBand()
    .domain(domainX)
    .rangeRound([ 0, bodyW ])
    .paddingOuter(1)
    .paddingInner(0.1);

  var scaleG = d3.scaleBand()
    .domain(domainG)
    .rangeRound([ 0, scaleX.bandwidth() ])
    .paddingInner(0.1);

  var scaleC = d3.scaleSequential(d3.interpolateYlGnBu)
    .domain(d3.extent(domainG))
    .domain([ domainG.length, - 1 ]);

  console.log(scaleC.domain())

  /* Prepare the plot basis. */
  var plot = d3.select(target)
    .append('svg')
      .attr('width', options.lengths.figure.width)
      .attr('height', options.lengths.figure.height)
      .classed(options.classes.figure, true);

  /* Prepare the body of the plot. */
  var body = plot.append('svg');
  body
    .classed('body', true)
    .attr('width', bodyW)
    .attr('height', bodyH)
    .attr('x', bodyX)
    .attr('y', bodyY);
  body
    .selectAll('svg')
    .data(payload)
    .enter()
      .append('svg')
    .classed('column-wrapper', true)
    .each(function (value, index) {
      var x = value[options.display.horizontal.key];
      var g = d3.select(this)
        .attr('width', scaleX.bandwidth())
        .attr('x', scaleX(x));

      options.display.vertical.forEach((field, offset) => {
        var y = value[field.key];
        var h = scaleY(0) - scaleY(y);

        if (lo.isEmpty(field.tooltip)) {
          field.tooltip = ':' + options.display.horizontal.key + ' ↦  :' + field.key;
        }

        let attrs = Object.assign({}, value);
        if (options.axes.vertical.ticks.format) {
          attrs[field.key] = d3.format(options.axes.vertical.ticks.format)(y);
        }
        let label = formatText(attrs, field.tooltip);

        var r = g.append('rect')
          .attr('x', scaleG(offset))
          .attr('y', h > 0 ? scaleY(y) : scaleY(0))
          .attr('width', scaleG.bandwidth())
          .attr('height', Math.abs(h))
          .attr('fill', field.color ? field.color : scaleC(offset))
          .on('click', $ => {
            displayFollowDialog(target, options, value);
          })
          .classed('plot-bar', true)
          .append('title')
            .text(label);
      });
    });

  /* Prepare the axes. */
  var axisHorizontal = d3.axisBottom()
    .scale(scaleX)
    .tickValues(options.axes.horizontal.ticks.values)
    .tickFormat(options.axes.horizontal.ticks.format
      ? d3.format(options.axes.horizontal.ticks.format)
      : null
    );

  var axisVertical = d3.axisLeft()
    .scale(scaleY)
    .tickValues(options.axes.vertical.ticks.values)
    .tickFormat(options.axes.vertical.ticks.format
      ? d3.format(options.axes.vertical.ticks.format)
      : null
    );

  if (Math.abs(d3.max(domainY)) < 10) {
    axisVertical.tickValues(domainY);
  }

  /* Produce the axes. */

  var axisX = plot.append('svg')
    .classed('axis-horizontal', true)
    .attr('x', bodyX)
    .attr('y', bodyY + bodyH + options.lengths.axis.horizontal.skip)
    .call(axisHorizontal);

  if (options.axes.horizontal.ticks.rotate) {
    axisX.selectAll('text').each(function () {
      var that = d3.select(this);
      var size = this.getBBox();
      var y = size.height / 3;

      that.attr('dy', 0)
      that.attr('y', 0)
      that.attr('transform', `translate(0, 10) rotate(90)`)
      that.style('text-anchor', 'start')
      that.style('alignment-baseline', 'middle')
    });
  }

  var axisY = plot.append('svg')
    .classed('axis-vertical', true)
    .attr('x', bodyX - options.lengths.axis.vertical.skip)
    .attr('y', bodyY)
    .call(axisVertical);

  /* Prepare the axis labels. */
  if (options.axes.horizontal.label) {
    var x = bodyW / 2;
    var y = options.lengths.axis.horizontal.skip * 2 + axisX.node().getBBox().height;
    axisX.append('text')
      .classed('label-horizontal', true)
      .attr('transform', `translate(${x}, ${y})`)
      .attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .text(options.axes.horizontal.label);
  }

  if (options.axes.vertical.label) {
    var x = - options.lengths.axis.vertical.skip * 2 - axisY.node().getBBox().width;
    var y = bodyH / 2
    axisY.append('text')
      .classed('label-vertical', true)
      .attr('transform', `translate(${x}, ${y}) rotate(-90)`)
      .attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .text(options.axes.vertical.label);
  }

  /* Prepare the reference lines. */
  if (options.refline && Array.isArray(options.refline)) {
    options.refline.forEach(value => {
      console.log('yay', value)
      body
        .append('line')
          .attr('class', 'plot-refline')
          .attr('x1', 0)
          .attr('x2', bodyW)
          .attr('y1', scaleY(value))
          .attr('y2', scaleY(value))
          .attr('shape-rendering', 'crispEdges')
          .attr('stroke', '#000');
    });
  }

  if (options.legends && options.legends.show) {

  /* Prepare the legends. */
  var legendW = options.margins.right - options.lengths.legend.skip;
  var legendH = options.display.vertical.length * (options.lengths.legend.line.height + options.lengths.legend.line.skip) - options.lengths.legend.line.skip;
  var legendX = bodyX + bodyW + 10;
  var legendY = bodyY + (bodyH - legendH) / 2;

  var legend = plot.append('svg')
    .attr('x', legendX)
    .attr('y', legendY)
    .attr('width', legendW)
    .attr('height', legendH);


  legend
    .selectAll('g')
    .data(options.display.vertical)
    .enter()
      .append('g')
    .attr('class', 'plot-legend-line')
    .each(function (value, index) {
      var line = d3.select(this);
      line
        .append('rect')
        .attr('y', index * (options.lengths.legend.line.skip + options.lengths.legend.line.height) - options.lengths.legend.line.height / 2)
        .attr('width', options.lengths.legend.line.height)
        .attr('height', options.lengths.legend.line.height)
        .attr('fill', value.color ? value.color : scaleC(index))
      line
        .append('text')
        .attr('y', index * (options.lengths.legend.line.skip + options.lengths.legend.line.height))
        .attr('x', 1.5 * options.lengths.legend.line.height)
        .attr('text-anchor', 'left')
        .attr('alignment-baseline', 'middle')
        .text(value.legend);
    });

  }

  /* Highlight! */
  // const lineHighlight = body
  //   .append('line')
  //     .classed('plot-highlight-line', true)
  //     .style('visibility', 'hidden')
  //     .style('stroke', 'black')
  //     .style('shape-rendering', 'crispEdges')

  // const labelHighlight = body
  //   .append('text')
  //     .classed('plot-highlight-label', true)
  //     .style('visibility', 'hidden')
  //     .style('text-anchor', 'middle')
  //     .style('alignment-baseline', 'baseline')
  //     .style('font-size', 'x-small')

  // body
  //   .selectAll('.plot-bar')
  //   .on('mouseover', function (d, index) {
  //     let y = Number.parseFloat(d3.select(this).attr('y'))
  //     let x = Number.parseFloat(d3.select(this.parentNode).attr('x'))
  //     let w = Number.parseFloat(d3.select(this).attr('width'))

  //     console.log(x, bodyW)

  //     lineHighlight
  //       .style('visibility', 'visible')
  //       .attr('x1', 0)
  //       .attr('x2', bodyW)
  //       .transition()
  //         .attr('y1', y)
  //         .attr('y2', y)

  //       /*      
  //       labelHighlight
  //       .style('visibility', 'visible')
  //         .attr('x', x + 0.5 * w)
  //         .attr('y', y - 5)
  //       .text(y)
  //       */
  //      
  //   })
  //   .on('mouseout', function (d, index) {
  //     lineHighlight
  //       .style('visibility', 'hidden')
  //   })

  



  /* Yey, its done. */
  return plot;
}

