export default class ModelCubeDimension {
  constructor (items, title, field) {
    this.$items = items
    this.$title = title
    this.$field = field
  }

  // Properties

  get field () {
    return this.$field
  }
  get title () {
    return this.$title
  }
  get items () {
    return this.$items
  }

  //

  get selected () {
    return this.items.filter(item => item.isSelected)
  }

  // Properties of items

  get isSelectedAll () {
    return this.$items.every(each => each.isSelected)
  }

  get isSelectedSome () {
    return this.$items.some(each => each.isSelected)
  }

  // Method

  toggleSelected (item) {
    item.isSelected = ! item.isSelected
  }

  toggleSelectAll () {
    const value = ! this.isSelectedAll
    this.$items.map(each => each.isSelected = value)
  }
}

// vim:syntax=javascript
